import centralBankLicense from '../../../assets/files/en/license_en.pdf';
import centralBankLicenseNew from '../../../assets/files/en/License_of_the_Central_Bank_of_the_Russian_Federation_No3538_dated_May_16_2024.pdf';

export default {
    seo: {
        title: 'Central Bank license | Bank 131',
        description: 'Our bank details'
    },
    title: 'Central Bank license',
    content: [
        {
            label: 'Posting date 23.05.2024',
            title: 'License of the Central Bank of the Russian Federation No. 3538 dated May 16, 2024.PDF',
            link: centralBankLicenseNew
        }
    ],
    voidedContentTitle: 'Voided as of May 16, 2024',
    voidedContent: [
        {
            label: 'Posting date 09.08.2019',
            title: 'License of the Central Bank of the Russian Federation No. 3538 dated April 12, 2019.PDF',
            link: centralBankLicense
        },
        {
            label: 'Posting date 26.01.2021',
            title: 'QR License of the Central Bank of the Russian Federation No 3538.pdf',
            link: '/legal/en/QR_License_of_the_Central_Bank_of_the_Russian_Federation_No_3538.pdf'
        }
    ]
}